.normal{
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.smallvalue{
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.main{
    margin: 0px auto;
    width: 500px;
}

.normal *{
    margin: 0px;
    padding: 10px;
}

.equal {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.equal *{
    margin: 0px;
    padding: 10px;
}

.smallvalue *{
    margin: 0px;
    padding: 10px;
}