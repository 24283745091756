.table, .th, .td{
    border: 1px solid black;
}

.th, .td{
    padding: 10px;
    text-align: left;
    width: 150px;
}

.overflowhide::-webkit-scrollbar {
    width: 10px;
}

.overflowhide{
    height: 24px;
    overflow-y: hidden;
}

.table{
    border-collapse: collapse;
    margin: 0px auto;
}

@media screen and (max-width:560px){
    .table
    {
        width: 100%;
    }
}