.main{
    margin: 0px auto;
    width: 500px;
}

.inputsection *{
    margin: 10px;
}

.buttonsection{
    padding: 5px;
    margin: 10px ;
    display: flex;
    justify-content: space-between;
}

.buttonsection:has(> :nth-child(1):last-child) {
	justify-content: flex-end;
}