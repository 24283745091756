.dropdown{
    border: 1px solid black;
    height: 25px;
    border-radius: 5px;
    width: 100%;
    padding: 0px 10px;
}

.normal{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;
    padding: 5px;
}

.compact, .compactwithlabels{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

.compact *, .compactwithlabels *, .normal *{
    margin: 0px;
}
