.navbar{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 10px;
    background-color: black;
}

.navbarlink{
    background-color: black;
    color: white;
    border-radius: 40px;
    font-weight: 500;
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid white;
}

.navbarlinkactive{
    background-color: white;
    color: black;
    border-radius: 40px;
    font-weight: 600;
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid white;
}