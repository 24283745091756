.iinput{
    border: 1px solid black;
    height: 25px;
    border-radius: 5px;
    width: 90%;
    padding: 0% 4%;
}

.iinput::-webkit-outer-spin-button,
.iinput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.equal{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.normal{
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.compact, .compactwithlabels{
    display: flex;
    flex-direction: column;
}

/* .compact div,.normal div, .compactwithlabels div{
    margin: 0px;
}

.compact, .normal, .compactwithlabels{
    margin: 0px;
}

.compact div .iinput, .normal div .iinput, .compactwithlabels div .iinput{
    margin: 0px;
} */

.compact *, .compactwithlabels *, .normal *, .equal *{
    margin: 0px;
}

.errormessage{
    font-size: 12px;
    color: red;
}