.homepage{
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}

.homepage a{
    background-color: black;
    color: aliceblue;
    border-radius: 40px;
    font-weight: 500;
    text-decoration: none;
    padding: 10px 30px;
}

/* For Home Page */

.homepagegrids{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.gridsection{
    width: calc(50vw - 32px);
    overflow-x: scroll;
}

.gridsection::-webkit-scrollbar{
    width: 4px;
}